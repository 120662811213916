
.header[data-v-6cf34095] {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card[data-v-6cf34095] {
    width: 360px;
    margin: auto;
    margin-block: 20px;
    display: block;
}
.image[data-v-6cf34095] {
    width: 300px;
    height: 300px;
    margin: auto;
    display: block;
}
